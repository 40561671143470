import { createSlice } from "@reduxjs/toolkit"
const initialState = {
  Submissions: [],
  SubmissionsMap: {},
  ratings: [],
  submissionSuccessMsg: "",
  submissionSuccess: false,
  submissionFailed: false,
  submissionFailedMsg: "",
}
export const SubmissionsSlice = createSlice({
  name: "SubmissionsSlice",
  initialState: initialState,
  reducers: {
    getEMCSubmissions: (currentState, action) => {},
    getSubmissions: (currentState, action) => {},
    storeEMCSubmissions: (currentState, action) => {
      currentState.Submissions = action.payload.Submissions
    },
    storeSubmissions: (currentState, action) => {
      currentState.SubmissionsMap[action.payload.assessmentId] =
        action.payload.Submissions
    },
    ratingSuccess: (currentState, action) => {
      currentState.submissionSuccessMsg = action.payload
      currentState.submissionSuccess = true
    },
    ratingFailed: (currentState, action) => {
      currentState.submissionFailedMsg = action.payload
      currentState.submissionFailed = true
    },
    submitRatings: (currentState, action) => {},
    getRatings: (currentState, action) => {},
    storeRatings: (currentState, action) => {
      currentState.ratings = action.payload
    },
  },
})
export default SubmissionsSlice.reducer
export const {
  getEMCSubmissions,
  getSubmissions,
  storeEMCSubmissions,
  storeSubmissions,
  submitRatings,
  ratingSuccess,
  ratingFailed,
  getRatings,
  storeRatings,
} = SubmissionsSlice.actions
