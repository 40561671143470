import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Login Redux States
import { loginSuccess, logoutUserSuccess, apiError } from "./slice"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { getQuestions, resetQuestions } from "store/firestore/questions/slice"
import { getUserSettings } from "store/firestore/settings/slice"
import { persistor } from "store"
const fireBaseBackend = getFirebaseBackend()

function* loginUserAPI({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )

      yield put(loginSuccess(response))
      yield put(getQuestions({ checkCache: true }))
      yield put(getUserSettings({ emailId: user.email }))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history("/admin")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUserAPI({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
      yield put(resetQuestions(response))
    }
    history("/index")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLoginAPI({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/admin")
      } else {
        history("/login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/admin")
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}
function* logoutUserSuccessSaga() {
  persistor.purge()
}
function* authSaga() {
  yield takeEvery("loginSlice/loginUser", loginUserAPI)
  yield takeLatest("loginSlice/socialLogin", socialLoginAPI)
  yield takeEvery("loginSlice/logoutUser", logoutUserAPI)
  yield takeEvery("loginSlice/logoutUserSuccess", logoutUserSuccessSaga)
}

export default authSaga
