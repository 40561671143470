import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getRatings } from "store/firestore/submissions/slice"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Table, Pagination, Row } from "react-bootstrap"
const Admin = () => {
  document.title = "Vema-Fest | Admin"
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRatings({}))
  }, [])

  const ratings = useSelector(state => {
    return state.Submissions.ratings
  })
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = ratings.slice(indexOfFirstItem, indexOfLastItem)

  // Calculate the total number of pages
  const totalPages = Math.ceil(ratings.length / itemsPerPage)

  // Handle page change
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const preferences = ratings.flatMap(obj =>
    Object.entries(obj)
      .filter(([key]) => /^\d+$/.test(key)) // Filter out non-numeric keys
      .map(([key, value]) => ({ preference: Number(key), projectId: value }))
  )

  const projectScores = preferences.reduce((acc, { preference, projectId }) => {
    const score = 6 - preference || 0
    acc[projectId] = (acc[projectId] || 0) + score
    return acc
  }, {})

  const sortedProjects = Object.entries(projectScores)
    .sort((a, b) => b[1] - a[1])
    .map(([projectId, totalScore]) => ({ projectId, totalScore }))

  const projectDetails = sortedProjects.map(({ projectId, totalScore }) => {
    const preferenceTotals = preferences.reduce(
      (acc, { preference, projectId: pid }) => {
        if (pid === projectId) {
          acc[preference] = (acc[preference] || 0) + 1
        }
        return acc
      },
      {}
    )

    return { projectId, totalScore, preferenceTotals }
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ratings" breadcrumbItem="Admin" />
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Device ID</th>
                  <th>Mobile</th>
                  <th>Invited By</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                </tr>
              </thead>
              <tbody>
                {ratings.map((item, index) => (
                  <tr key={index}>
                    <td>{item.deviceId}</td>
                    <td>{item.mobile}</td>
                    <td>{item.invitedBy}</td>
                    <td>{item.date}</td>
                    <td>{item.name}</td>
                    <td>{item["1"]}</td>
                    <td>{item["2"]}</td>
                    <td>{item["3"]}</td>
                    <td>{item["4"]}</td>
                    <td>{item["5"]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* <Pagination>
              {Array.from({ length: totalPages }, (_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination> */}
          </Row>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Project ID</th>
                  <th>Total Score</th>
                  <th>1st Preference</th>
                  <th>2nd Preference</th>
                  <th>3rd Preference</th>
                  <th>4th Preference</th>
                  <th>5th Preference</th>
                </tr>
              </thead>
              <tbody>
                {projectDetails.map(
                  ({ projectId, totalScore, preferenceTotals }, index) => (
                    <tr key={index}>
                      <td>{projectId}</td>
                      <td>{totalScore}</td>
                      <td>{preferenceTotals[1] || 0}</td>
                      <td>{preferenceTotals[2] || 0}</td>
                      <td>{preferenceTotals[3] || 0}</td>
                      <td>{preferenceTotals[4] || 0}</td>
                      <td>{preferenceTotals[5] || 0}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Admin
