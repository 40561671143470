import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import Admin from "pages/Admin/admin"
import Login from "../pages/Authentication/Login"
// Authentication related pages
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Ratings from "pages/Ratings"

const authProtectedRoutes = [
  { path: "/profile", component: <UserProfile /> },
  { path: "/admin", component: <Admin /> },
]

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: <Ratings />,
  },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/index", component: <Ratings /> },
]

export { authProtectedRoutes, publicRoutes }
