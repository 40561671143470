import { takeEvery, all, fork, call, put, select } from "redux-saga/effects"
import { db } from "App"
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
} from "firebase/firestore"
import { storeEMCSubmissions, storeSubmissions } from "./slice"
import { ratingSuccess, ratingFailed } from "./slice"
import { storeRatings } from "./slice"
function* getEMCSubmissionsAPI({ payload }) {
  const emailId = payload.emailId
  const startDate = payload.startDate
  const endDate = payload.endDate
  const submissionsRef = collection(db, "submissions")
  const emotionalCheckInQuery = query(
    submissionsRef,
    where("assessment_id", "==", "emotional_check_in"),
    where("user_id", "==", emailId),
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  )

  const emotionalCheckInData = yield call(getDocs, emotionalCheckInQuery)
  const submissions = []
  emotionalCheckInData.forEach(doc => {
    submissions.push(doc.data())
  })
  yield put(
    storeEMCSubmissions({
      Submissions: submissions,
    })
  )
}

function* getSubmissionsAPI({ payload }) {
  const emailId = payload.emailId
  const startDate = payload.startDate
  const endDate = payload.endDate
  const assessmentId = payload.assessmentId
  const submissionsRef = collection(db, "submissions")
  const submissionsQuery = query(
    submissionsRef,
    where(
      "assessment_id",
      "==",
      assessmentId ? assessmentId : "emotional_check_in"
    ),
    where("user_id", "==", emailId),
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  )

  const submissionsQueryData = yield call(getDocs, submissionsQuery)
  const submissions = []
  submissionsQueryData.forEach(doc => {
    submissions.push(doc.data())
  })
  yield put(
    storeSubmissions({
      assessmentId: assessmentId,
      Submissions: submissions,
    })
  )
}
function* submitRatingsAPI({ payload: { ratings } }) {
  try {
    yield call(setDoc, doc(collection(db, "ratings")), ratings)
    yield put(ratingSuccess("Submission Successfull"))
  } catch (error) {
    console.log(error)
    yield put(ratingFailed("Error in recording your response"))
  }
}

function* getRatingsAPI() {
  const ratingsSnapshot = yield call(getDocs, collection(db, "ratings"))
  const allrating = []
  ratingsSnapshot.forEach(doc => {
    allrating.push(doc.data())
  })
  yield put(storeRatings(allrating))
}

function* SubmissionsSaga() {
  yield takeEvery("SubmissionsSlice/getEMCSubmissions", getEMCSubmissionsAPI)
  yield takeEvery("SubmissionsSlice/getSubmissions", getSubmissionsAPI)
  yield takeEvery("SubmissionsSlice/submitRatings", submitRatingsAPI)
  yield takeEvery("SubmissionsSlice/getRatings", getRatingsAPI)
}
export default SubmissionsSaga
