import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Form, Button } from "react-bootstrap"
import Info from "components/Common/Info"
import { withTranslation } from "react-i18next"
import logo from "../../assets/images/logo.png"
import c from "./style.module.css"
import { submitRatings } from "store/firestore/submissions/slice"
import { createSelector } from "reselect"
import { v4 as uuidv4 } from "uuid"
const Ratings = props => {
  //meta title
  document.title = "Vema | Science Fest"
  const [name, setName] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [invitedBy, setInvitedBy] = useState("")
  const [innovations, setInnovations] = useState(["", "", "", "", ""])
  const [showInfo, setShowInfo] = useState(false)
  const [infoMsg, setInfoMsg] = useState([])
  const [infoTitle, setInfoTitle] = useState([])

  const handleInnovationChange = (event, index) => {
    const newInnovations = [...innovations]
    newInnovations[index] = event.target.value
    setInnovations(newInnovations)
  }

  const hasEmptyFields = [
    name,
    mobileNumber,
    invitedBy,
    innovations[0],
    innovations[1],
  ].some(field => field === "")

  const submissionProperties = createSelector(
    state => state.Settings,
    settings => ({
      submissionSuccess: settings.submissionSuccess,
      submissionSuccessMsg: settings.submissionSuccessMsg,
      submissionFailed: settings.submissionError,
      submissionFailedMsg: settings.submissionErrorMsg,
    })
  )
  const dispatch = useDispatch()
  const {
    submissionSuccess,
    submissionSuccessMsg,
    submissionFailed,
    submissionFailedMsg,
  } = useSelector(submissionProperties)
  useEffect(() => {
    // Check if device ID exists in localStorage, if not generate one
    if (!localStorage.getItem("deviceId")) {
      localStorage.setItem("deviceId", uuidv4())
    }
  }, [])
  function hasDuplicates(arr) {
    const filteredArr = arr.filter(item => item !== "")
    const set = new Set(filteredArr)
    return set.size !== filteredArr.length
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (hasDuplicates(innovations)) {
      setInfoTitle("Caution")
      setInfoMsg("Can not add same project at multiple preferences...!")
      setShowInfo(true)
      return
    }
    if (mobileNumber.length != 10) {
      setInfoTitle("Caution")
      setInfoMsg("Invalid Mobile Number....!")
      setShowInfo(true)
      return
    }
    const ratings = {
      name: name,
      mobile: mobileNumber,
      invitedBy: invitedBy,
      deviceId: localStorage.getItem("deviceId"),
      date: new Date().toISOString(),
      1: innovations[0],
      2: innovations[1],
      3: innovations[2],
      4: innovations[3],
      5: innovations[4],
    }

    dispatch(submitRatings({ ratings: ratings }))
    setInfoTitle("Success")
    setInfoMsg("Your rating recorded...!")
    setShowInfo(true)
    setMobileNumber("")
    setInvitedBy("")
    setName("")
    setInnovations(["", "", "", "", ""])
  }

  return (
    <React.Fragment>
      <Info
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        infoMsg={infoMsg}
        infoTitle={infoTitle}
      ></Info>
      <div>
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col>
              <img src={logo} alt="" height="100" className="mx-auto d-block" />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center">
            <Col className="text-center" style={{ color: "#f9ab41" }}>
              <h2>Vema High School</h2>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row className={c.row}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                  required
                />
              </Form.Group>
            </Row>
            <Row className={c.row}>
              <Form.Group controlId="formMobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your mobile number"
                  minLength={10}
                  value={mobileNumber}
                  onChange={event => setMobileNumber(event.target.value)}
                  required
                />
              </Form.Group>
            </Row>
            <Row className={c.row}>
              <Form.Group controlId="formInvitedBy">
                <Form.Label>Invited By</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the student who invited you"
                  value={invitedBy}
                  onChange={event => setInvitedBy(event.target.value)}
                  required
                />
              </Form.Group>
            </Row>
            <Row className={c.row}>
              <Form.Group controlId="formInnovations">
                <Form.Label>Your preferences (at least two)</Form.Label>
                <Col sm={12} className={c.preference}>
                  <Form.Control
                    type="number"
                    placeholder="First preference"
                    value={innovations[0]}
                    onChange={event => handleInnovationChange(event, 0)}
                    required
                  />
                </Col>
                <Col sm={12} className={c.preference}>
                  <Form.Control
                    type="number"
                    placeholder="Second preference"
                    value={innovations[1]}
                    onChange={event => handleInnovationChange(event, 1)}
                    required
                  />
                </Col>
                <Col sm={12} className={c.preference}>
                  <Form.Control
                    type="number"
                    placeholder="Third preference (optional)"
                    value={innovations[2]}
                    onChange={event => handleInnovationChange(event, 2)}
                  />
                </Col>
                <Col sm={12} className={c.preference}>
                  <Form.Control
                    type="number"
                    placeholder="Fourth preference (optional)"
                    value={innovations[3]}
                    onChange={event => handleInnovationChange(event, 3)}
                  />
                </Col>
                <Col sm={12} className={c.preference}>
                  <Form.Control
                    type="number"
                    placeholder="Fifth preference (optional)"
                    value={innovations[4]}
                    onChange={event => handleInnovationChange(event, 4)}
                  />
                </Col>
              </Form.Group>
            </Row>

            <Row className={c.row}>
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={hasEmptyFields}
                  className={c.button}
                >
                  Submit
                </Button>
              </Col>
            </Row>
            <Row>
              <Col style={{ minHeight: "60px" }}></Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

Ratings.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Ratings)
